import React from 'react';
import { Typography, Button, Box, Stack,  Grid, useMediaQuery } from '@mui/material';
import contact from '../assets/images/contactheader.png';
import delivervan from '../assets/images/Deliveryvan.png';
import TextField from '@mui/material/TextField';
import Contact1 from '../components/contact';
import contactusimg from '../assets/images/contactimg.png'
import Footer from '../components/Footer';

import SendIcon from '@mui/icons-material/Send';
function ContactUsPage() {
  const isMatch = useMediaQuery("(min-width:650px)")
  return (
    <>
      <Stack style={{ backgroundImage: `url(${contact})`, backgroundSize: '100% 100%', backgroundPosition: 'center',width:isMatch?'1420px': '393px' , height:  isMatch? '380px': '170px'  }}>
        <Typography sx={{ fontSize: isMatch ? "50px" : "22px", color: "#fff", justifyContent: "left", pt: isMatch ? 38 : 15, paddingLeft: isMatch ? "100px" : "20px", fontFamily: 'Rajdhani', fontWeight: isMatch ? 600 : 700 }}>Contact us</Typography>
      </Stack>

      <Grid container spacing={2} direction={isMatch ? "row" : "column"}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Stack marginLeft={isMatch ? '150px' : '20px'} left={isMatch ? "50%" : "5%"} marginTop={isMatch ? '80px' : '20px'} marginBottom={isMatch ? '10px' : '0'}>
            <Typography sx={{ fontSize: isMatch ? '50px' : '16px', fontFamily: isMatch ? 'Poppins' : 'Poppins', fontWeight: isMatch ? '500' : '500' }}>Get in touch</Typography>
            <Typography sx={{ fontSize: isMatch ? '16px' : '12px', fontFamily: isMatch ? 'Poppins' : 'Inter', fontWeight: isMatch ? '500' : '500' }}>To get in touch with global Logistics please call or email us.<br /> For site specific contact details please use for below.</Typography>
          </Stack>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <img
            src={delivervan}
            style={{ width: isMatch ? '400px' : '220px', height: isMatch ? '242px' : '149px', marginTop: isMatch ? "40px" : "-25px", marginLeft: isMatch ? "100px" : "70px" }}
            alt="Contact Image"
          />
        </Grid>
      </Grid>


      <Stack width={isMatch ? "100%" : "268px"} marginLeft={isMatch ? "0" : "50px"} marginTop={isMatch?'-50px':'0px'} >
        <Contact1 />
      </Stack>
      <Grid container spacing={1} direction={isMatch ? "row" : "column-reverse"}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Stack marginLeft={isMatch ? '150px' : '30px'} marginTop={isMatch ? '30px' : '30px'} marginBottom={'10px'}>
            <Typography sx={{ fontSize: isMatch ? '30px' : '16px', fontFamily: 'Poppins', fontWeight: '400' }}>Write us a Message</Typography>
            <Typography sx={{ fontSize: isMatch ? '14px' : '12px', fontFamily: 'Poppins', fontWeight: '500', color: '#a8a69e', marginTop: '10px' }}>Please give us a call.drop us an email or fill out the contact<br />form and we'll get back to you.</Typography>
            <TextField label="Your Name*" variant="outlined" sx={{ width: isMatch ? '500px' : '326px', height: isMatch ? '55px' : '36px', fontSize: '20px', fontFamily: 'Inter', fontWeight: '400', backgroundColor: '#fff', marginTop: '10px' }} />
            <TextField label="Email" type="email" variant="outlined" sx={{ width: isMatch ? '500px' : '326px', height: isMatch ? '55px' : '36px', backgroundColor: '#fff', marginTop: isMatch ? '10px' : '30px' }} />
            <TextField InputProps={{ multiline:true}}  label="Message" variant="outlined" sx={{ width: isMatch ? '500px' : '326px',"& .MuiInputBase-root": {  height: isMatch ? '107px' : '90px'}, backgroundColor: '#fff', marginTop: isMatch ? '10px' : '30px' }} />
            <Typography sx={{ marginTop: isMatch ? 3 : '20px', marginBottom: '20px', marginLeft: isMatch ? '180px' : '110px' }}>
              <Button style={{ fontSize: '16px', width: isMatch ? '140px' : '125px', height: isMatch ? '42px' : '35px', backgroundColor: '#7A4AE2', borderRadius: '5px', fontFamily: 'Poppins', fontWeight: '700' }} variant="contained" endIcon={<SendIcon />}>
                Send
              </Button></Typography>

          </Stack>
        </Grid>


        <Grid item lg={6} md={6} sm={12} xs={12}>
          <img
            src={contactusimg}
            style={{ width: isMatch ? '80%' : '50%', maxWidth: '40%', height: 'auto', marginTop: isMatch ? "30px" : "50px", marginLeft: isMatch ? "150px" : "100px" }}
            alt="Contact Image"
          />
        </Grid>
      </Grid>

      <Stack style={{marginTop:isMatch?'-25px':'-40px'}}>
          <Footer />
          </Stack>
    </>
  );
}

export default ContactUsPage;