import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {Typography,Box} from '@mui/material';
import { useMediaQuery } from '@mui/material';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
    
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:'white',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(3),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

 function Faq() {
  const [expanded, setExpanded] = React.useState('panel');
  const isMatch=useMediaQuery("(min-width:650px)")
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const typoans = {fontFamily: isMatch?'Rubik':'Rubik' ,fontSize:isMatch?'16px':'12px' };
  const typoqus = { ml: '10px', fontFamily: isMatch?'Rubik':'Rubik' ,fontSize:isMatch?'16px':'12px' };
 
  return (
    
    <Box sx={{ width: isMatch?'80%':'90%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
   
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{marginBottom:isMatch?'20px':'10px'}}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Typography sx={typoqus}>What is DLX app, and how does it work? </Typography>
         </AccordionSummary>
        <AccordionDetails>
          <Typography sx={typoans}>
          Our DLX is a mobile app that connects consignors, consignees, and track operators, providing a streamlined and secure platform for managing logistics operations. Users can book shipments, track shipments in real-time, and manage their logistics operations efficiently and effectively.,
   
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{marginBottom:isMatch?'20px':'10px'}}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography sx={typoqus}>"Is your logistics application secure?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={typoans}>
          Yes, we take security seriously, and DLX is designed with robust security measures to ensure the secure delivery of shipments. Our platform includes features such as driver verification, delivery confirmation, and secure storage, providing peace of mind to both consignors and consignees.
         </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{marginBottom:isMatch?'20px':'10px'}}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography sx={typoqus}> "Can I track my shipment in real-time?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={typoans}>
            Yes, DLX provides real-time tracking of shipments, enabling consignors, consignees, and track operators to monitor the location and status of shipments at all times.
    </Typography>
        </AccordionDetails>
      </Accordion>
      
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} sx={{marginBottom:isMatch?'20px':'10px'}}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography sx={typoqus}> "What types of shipments can I book on your logistics application? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={typoans}>
            DLX supports a wide range of shipments, including parcels, pallets, and full container loads.</Typography>
        </AccordionDetails>
      </Accordion>
      
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} sx={{marginBottom:isMatch?'20px':'10px'}}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography sx={typoqus}> What if I have a problem with my shipment?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={typoans}>
          If you encounter any issues with your shipment, our platform provides tools for reporting and resolving issues promptly.
           Our customer support team is also available to assist you with any issues you may encounter.</Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default Faq;

