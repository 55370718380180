

import React from 'react';
import { Typography, Button, Box, Stack, Card, CardContent, Grid, useMediaQuery} from '@mui/material';
import services from '../assets/images/services.png'
import servicesBg from '../assets/images/servicesBg.png';
import icBulb from '../assets/svg/icBulb.svg';
import icEye from '../assets/svg/icEye.svg';
import icHand from '../assets/svg/icHand.svg';
import icMaj from '../assets/svg/icMaj.svg';
import icMob from '../assets/svg/icMob.svg';
import icSteam from '../assets/svg/icSteam.svg';
import serviceEra1 from '../assets/images/serviceEra1.svg';
import serviceEra2 from '../assets/images/serviceEra2.svg';
import serviceEra3 from '../assets/images/serviceEra3.svg';

import vector from '../assets/images/Vector1.png';
import rectang from '../assets/images/Rectangle 4915.png';
import Footer from '../components/Footer';

import { useNavigate } from 'react-router-dom';
function ServicesPage() {
  const isMatch = useMediaQuery("(min-width:650px)")
  const navigate = useNavigate();
  const cardDesign = { backgroundColor: '#ffffff', color: '#202020', p: 1, width: isMatch ? 350 : 173, height: isMatch ? 320 : 240, marginLeft: '2vw', marginBottom: '1vw' };
  const cardSub = isMatch ? { fontFamily: 'Rubik', fontSize: 16, color: '#777777' } : { fontFamily: 'Inter', fontSize: 11, color: '#777777', fontWeight: '500' }
  const cardMain = isMatch ? { fontFamily: "Rubik", fontSize: 22, color: '#202020' } : { fontFamily: "Inter", fontSize: 14, color: '#202020', fontWeight: '500' }
  const cardIc = isMatch ? { height: '40px', width: '40px' } : { height: '25px', width: '25px' }

  return (
    <>

      <Stack style={{ backgroundImage: `url(${services})`, backgroundSize: '100% 100%', backgroundPosition: 'center', width: isMatch ? '100%' : '400px', height: isMatch ? '400px' : '170px' }}>
        <Typography sx={{ fontSize: isMatch ? "50px" : "22px", color: "#fff", justifyContent: "left", pt: isMatch ? 40 : 15, paddingLeft: isMatch ? "100px" : "20px", fontFamily: 'Rajdhani', fontWeight: isMatch ? 600 : 700 }}>Services</Typography>
      </Stack>


      {/*WEBSITE VIEW*/}
      <Stack display={{ xs: "none", lg: "flex" }} style={{ backgroundImage: `url(${servicesBg})`, backgroundSize: 'cover', backgroundPosition: 'absolute' }}>
        <Stack marginLeft={{ xs: '10px', md: '100px' }} marginTop={{ xs: '10px', md: '80px' }} >
          <Typography sx={{ fontSize: isMatch ? '30px' : '18px', fontFamily: isMatch ? 'Rajdhani' : 'Poppins', fontWeight: isMatch ? '600' : '500' }}> why you choose us</Typography>
        </Stack>
        <Stack direction={"row"} mt={4} ml={isMatch ? 15 : 0}>

          <Card sx={cardDesign} >
            <Stack direction="row">
              <Box component="img" alt="icBulb" src={icBulb} sx={cardIc} title='Innovative Hub' />
              <Typography sx={cardMain}>&emsp;Innovative Hub</Typography>
            </Stack>
            <hr />
            <CardContent>
              <Typography sx={cardSub}>At DLX, we're
                setting the pace by embracing cutting-edge technologies. Our logistics solutions are powered by the latest innovations,
                ensuring that your shipments are not just delivered but are on a journey through a technological marvel with safe way.</Typography>
            </CardContent>
          </Card>

          <Card sx={cardDesign} >
            <Stack direction="row">
              <Box component="img" alt="icSteam" src={icSteam} sx={cardIc} />
              <Typography sx={cardMain}>&emsp;Seamless Logistics</Typography>
            </Stack>
            <hr />
            <CardContent>

              <Typography sx={cardSub}>DLX makes
                a harmonious logistics experience from start to finish. Our integrated solution is like a well-tuned symphony,
                where booking, tracking, and managing your shipments are not just tasks but interconnected movements in a logistics masterpiece.</Typography>
            </CardContent>
          </Card>

          <Card sx={cardDesign} >
            <Stack direction="row">
              <Box component="img" alt="icMaj" src={icMaj} sx={cardIc} />
              <Typography sx={cardMain}>&emsp;Maestros of Logistics</Typography>
            </Stack>
            <hr />
            <CardContent>
              <Typography sx={cardSub}>Entrust your goods to the maestros at DLX – a team of seasoned logistics expert. With a wealth of industry expertise,
                our professionals ensure that each shipment is handled with precision, care, and an creativity that only experience can bring.</Typography>
            </CardContent>
          </Card>
        </Stack>
        <Stack direction={"row"} mt={1} ml={isMatch ? 15 : 0}>
          <Card sx={cardDesign} >
            <Stack direction="row">
              <Box component="img" alt="icEye" src={icEye} sx={cardIc} />
              <Typography sx={cardMain}>&emsp; Transport of Operations </Typography>
            </Stack>
            <hr />
            <CardContent>

              <Typography sx={cardSub}>Transparency is not a feature; it's woven into the very fabric of DLX operations.
                The real-time tracking and updates create a vivid tapestry, allowing you to witness the journey of your shipments with clarity and confidence.
              </Typography>
            </CardContent>
          </Card>

          <Card sx={cardDesign} >
            <Stack direction="row">
              <Box component="img" alt="icMob" src={icMob} sx={cardIc} />
              <Typography sx={cardMain}>&emsp;Mobile Elegance in Logistics</Typography>
            </Stack>
            <hr />

            <CardContent><Typography sx={cardSub}>Our mobile app isn't just user-friendly; it's a mobile elegance in logistics management. Booking,
              managing, and tracking your shipments become a seamless and stylish experience, putting the power of logistics in the palm of your hand.
            </Typography>
            </CardContent>
          </Card>

          <Card sx={cardDesign} >
            <Stack direction="row">
              <Box component="img" alt="icHand" src={icHand} sx={cardIc} />
              <Typography sx={cardMain}>&emsp;Reliability Redefined</Typography>
            </Stack>
            <hr />
            <CardContent>

              <Typography sx={cardSub}>DLX doesn't just deliver; we redefine reliability. Consider us your logistics guardian, ensuring that your
                goods reach their destination not just on time, but with reliability that transforms logistics from a service into a trusted partnership
              </Typography>
            </CardContent>
          </Card>
        </Stack>
      </Stack>

      {/*MOBILE VIEW*/}
      <Stack display={{ xs: "flex", lg: "none" }} style={{ backgroundImage: `url(${servicesBg})`, backgroundSize: 'cover', backgroundPosition: 'absolute' }}>
        <Stack marginLeft={{ xs: '10px', md: '100px' }} marginTop={{ xs: '10px', md: '80px' }} spacing={2}>
          <Typography sx={{ fontSize: isMatch ? '30px' : '18px', fontFamily: isMatch ? 'Rajdhani' : 'Poppins', fontWeight: isMatch ? '600' : '500' }}> why you choose us</Typography>
        </Stack>
        <Grid container direction="row" marginTop='10px' columnSpacing={1} marginLeft={isMatch ? 13 : 0}>
          <Card sx={cardDesign} >
            <Stack direction="row">
              <Box component="img" alt="icBulb" src={icBulb} sx={cardIc} title='Innovative Hub' />
              <Typography sx={cardMain}>&emsp;Innovative Hub</Typography>
            </Stack>
            <hr />
            <CardContent>
              <Typography sx={cardSub}>At DLX, we're
                setting the pace by embracing cutting-edge technologies. Our logistics solutions are powered by the latest innovations,
                ensuring that your shipments are not just delivered but are on a journey through a technological marvel with safe way.</Typography>
            </CardContent>
          </Card>

          <Card sx={cardDesign} >
            <Stack direction="row">
              <Box component="img" alt="icSteam" src={icSteam} sx={cardIc} />
              <Typography sx={cardMain}>&emsp;Seamless Logistics</Typography>
            </Stack>
            <hr />
            <CardContent>

              <Typography sx={cardSub}>DLX makes
                a harmonious logistics experience from start to finish. Our integrated solution is like a well-tuned symphony,
                where booking, tracking, and managing your shipments are not just tasks but interconnected movements in a logistics masterpiece.</Typography>
            </CardContent>
          </Card>

          <Card sx={cardDesign} >
            <Stack direction="row">
              <Box component="img" alt="icMaj" src={icMaj} sx={cardIc} />
              <Typography sx={cardMain}>&emsp;Maestros of Logistics</Typography>
            </Stack>
            <hr />
            <CardContent>
              <Typography sx={cardSub}>Entrust your goods to the maestros at DLX – a team of seasoned logistics expert. With a wealth of industry expertise,
                our professionals ensure that each shipment is handled with precision, care, and an creativity that only experience can bring.</Typography>
            </CardContent>
          </Card>


          <Card sx={cardDesign} >
            <Stack direction="row">
              <Box component="img" alt="icEye" src={icEye} sx={cardIc} />
              <Typography sx={cardMain}>&emsp; Transport of Operations </Typography>
            </Stack>
            <hr />
            <CardContent>

              <Typography sx={cardSub}>Transparency is not a feature; it's woven into the very fabric of DLX operations.
                The real-time tracking and updates create a vivid tapestry, allowing you to witness the journey of your shipments with clarity and confidence.
              </Typography>
            </CardContent>
          </Card>

          <Card sx={cardDesign} >
            <Stack direction="row">
              <Box component="img" alt="icMob" src={icMob} sx={cardIc} />
              <Typography sx={cardMain}>&emsp;Mobile Elegance in Logistics</Typography>
            </Stack>
            <hr />

            <CardContent><Typography sx={cardSub}>Our mobile app isn't just user-friendly; it's a mobile elegance in logistics management. Booking,
              managing, and tracking your shipments become a seamless and stylish experience, putting the power of logistics in the palm of your hand.
            </Typography>
            </CardContent>
          </Card>

          <Card sx={cardDesign} >
            <Stack direction="row">
              <Box component="img" alt="icHand" src={icHand} sx={cardIc} />
              <Typography sx={cardMain}>&emsp;Reliability Redefined</Typography>
            </Stack>
            <hr />
            <CardContent>

              <Typography sx={cardSub}>DLX doesn't just deliver; we redefine reliability. Consider us your logistics guardian, ensuring that your
                goods reach their destination not just on time, but with reliability that transforms logistics from a service into a trusted partnership
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Stack>


      <Typography variant="h4" sx={{ marginLeft: isMatch ? '4rem' : '1rem', marginTop: '30px', fontFamily: 'Poppins', fontWeight: '500', fontSize: isMatch ? '35px' : '18px' }} >
        Welcome to digital era of logistics
      </Typography>

      {/* website view */}
      <Stack display={{ xs: "none", lg: "flex" }} >
        <Grid container spacing={3} justifyContent="center" marginLeft={0} >
          <Grid item xs={12} sm={6} md={4}  >
            <div style={{ marginTop: '1rem', marginLeft: isMatch ? '1rem' : '-3rem', backgroundColor: '#ebeceb', borderRadius: '2PX', padding: '15px', width: isMatch ? '306px' : '179px', height: isMatch ? '40px' : '20px' }}>
              <Typography sx={{ fontFamily: 'Rubik', fontWeight: '500', fontSize: isMatch ? '23px' : '14px', color: '#5822DE' }}> Customer Focus</Typography>
            </div>
            <div style={{ marginBottom: '1px', marginLeft: isMatch ? '1rem' : '-3rem', backgroundColor: '#ebeceb', width: isMatch ? '335px' : '208.5px', height: isMatch ? '130px' : '105px' }}>
              <Stack style={{ paddingTop: '1rem', paddingBottom: '1rem', backgroundSize: '100% 100%', backgroundImage: `url(${rectang})`, backgroundPosition: 'absolute', width: isMatch ? '335px' : '208px', height: isMatch ? '130px' : '87px' }}>
                <Typography sx={{ marginLeft: '12px', fontFamily: 'Rubik', fontWeight: '500', fontSize: isMatch ? '16px' : '10px', color: '#fff' }}>
                  We prioritize understanding and fulfilling your specific needs.
                  Safe and secure transportation with proof of delivery is paramount.</Typography>
              </Stack>
            </div>
            <div style={{ marginBottom: '100px', marginLeft: isMatch ? '1rem' : '-3rem', backgroundColor: '#ebeceb', width: isMatch ? '335px' : '208px', height: isMatch ? '200px' : '100px' }}>
              <img src={serviceEra1}
                style={{ width: isMatch ? '250px' : '125px', height: isMatch ? '200px' : '89px', marginBottom: '20px', marginLeft: '35px' }}
                alt="Contact Image"
              />
            </div>
          </Grid>


          <Grid item xs={12} sm={6} md={4} marginTop={isMatch ? '0' : '0'}>
            <div style={{ marginTop: '1rem', marginLeft: '1rem', backgroundColor: '#ebeceb', borderRadius: '2PX', padding: '15px', width: isMatch ? '306px' : '179px', height: isMatch ? '40px' : '20px' }}>
              <Typography sx={{ fontFamily: 'Rubik', fontWeight: '500', fontSize: isMatch ? '23px' : '14px', color: '#5822DE' }}> Empowering Transporters</Typography>
            </div>
            <div style={{ marginBottom: '1px', marginLeft: '1rem', backgroundColor: '#ebeceb', width: isMatch ? '335px' : '208.5px', height: isMatch ? '130px' : '85px' }}>
              <Stack style={{ paddingTop: '1rem', backgroundSize: '100% 100%', backgroundImage: `url(${rectang})`, backgroundPosition: 'absolute', width: isMatch ? '335px' : '208px', height: isMatch ? '158px' : '87px' }}>

                <Typography sx={{ marginLeft: '12px', fontFamily: 'Rubik', fontWeight: '500', fontSize: isMatch ? '16px' : '10px', color: '#fff' }}>
                  We ensure timely payment settlements <br />upon POD confirmation within the app,<br />
                  fostering a reliable partnership.</Typography>
              </Stack>
            </div>
            <div style={{ marginBottom: '100px', marginLeft: '1rem', backgroundColor: '#ebeceb', width: isMatch ? '335px' : '208px', height: isMatch ? '200px' : '100px' }}>
              <img src={serviceEra2}
                style={{ width: isMatch ? '230px' : '125px', height: isMatch ? '180px' : '89px', marginBottom: '20px', marginLeft: '30px' }}
                alt="Contact Image"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div style={{ marginTop: '1rem', marginLeft: '0', backgroundColor: '#ebeceb', borderRadius: '2PX', padding: '15px', width: '306px', height: '40px' }}>
              <Typography sx={{ fontFamily: 'Rubik', fontWeight: '500', fontSize: '23px', color: '#5822DE' }}> Beyond Full Loads</Typography>
            </div>
            <div style={{ marginBottom: '1px', marginLeft: '0', backgroundColor: '#ebeceb', width: '335px', height: '120px' }}>
              <Stack style={{ backgroundSize: '100% 100%', backgroundImage: `url(${rectang})`, backgroundPosition: 'absolute', height: '158px' }}>

                <Typography sx={{ marginLeft: '12px', fontFamily: 'Rubik', fontWeight: '500', fontSize: '16px', color: '#fff' }}>
                  Unlike competitors solely focused on<br /> bulk shipments, Dlx Logistics Private<br />
                  Limited caters to B2C needs,<br /> supporting your daily business operations.</Typography></Stack>
            </div>
            <div style={{ marginBottom: '100px', marginLeft: '0', backgroundColor: '#ebeceb', width: '335px', height: '200px' }}>
              <img src={serviceEra3}
                style={{ width: '250px', height: '200px', marginBottom: '20px', marginLeft: '40px' }}
                alt="Contact Image"
              />
            </div>
          </Grid>
        </Grid>
      </Stack>



      {/* mobile view */}
      <Stack display={{ xs: "flex", lg: "none" }} >

        <Stack direction={"row"} marginTop={"10px"} >
          <Grid item xs={12} sm={6} md={4} marginLeft={isMatch ? '0' : '2.5rem'} >
            <div style={{ marginTop: '1rem', marginLeft: isMatch ? '1rem' : '-3rem', backgroundColor: '#ebeceb', borderRadius: '2PX', padding: '15px', width: isMatch ? '306px' : '175px', height: isMatch ? '40px' : '20px' }}>
              <Typography sx={{ fontFamily: 'Rubik', fontWeight: '500', fontSize: isMatch ? '26px' : '14px', color: '#5822DE' }}> Customer Focus</Typography>
            </div>
            <div style={{ marginBottom: '1px', marginLeft: isMatch ? '1rem' : '-3rem', backgroundColor: '#ebeceb', width: isMatch ? '335px' : '205.5px', height: isMatch ? '130px' : '85px' }}>
              <Stack style={{ paddingTop: '1rem', paddingBottom: '1rem', backgroundSize: '100% 100%', backgroundImage: `url(${rectang})`, backgroundPosition: 'absolute', width: isMatch ? '335px' : '205px', height: isMatch ? '130px' : '87px' }}>
                <Typography sx={{ marginLeft: '12px', fontFamily: 'Rubik', fontWeight: '500', fontSize: isMatch ? '18px' : '9px', color: '#fff' }}>
                  We prioritize understanding and fulfilling your specific needs.
                  Safe and secure transportation with proof of delivery is paramount.</Typography>
              </Stack>
            </div>
            <div style={{ marginBottom: '100px', marginLeft: isMatch ? '1rem' : '-3rem', backgroundColor: '#ebeceb', width: isMatch ? '335px' : '205px', height: isMatch ? '200px' : '100px' }}>
              <img src={serviceEra1}
                style={{ width: isMatch ? '250px' : '125px', height: isMatch ? '200px' : '89px', marginLeft: '35px' }}
                alt="Contact Image"
              />
            </div>
          </Grid>


          <Grid item xs={12} sm={6} md={4} marginLeft={isMatch ? '0' : '-0.5rem'}>
            <div style={{ marginTop: '1rem', marginLeft: '1rem', backgroundColor: '#ebeceb', borderRadius: '2PX', padding: '15px', width: isMatch ? '306px' : '175px', height: isMatch ? '40px' : '20px' }}>
              <Typography sx={{ fontFamily: 'Rubik', fontWeight: '500', fontSize: isMatch ? '26px' : '14px', color: '#5822DE' }}> Empowering Transporters</Typography>
            </div>
            <div style={{ marginBottom: '1px', marginLeft: '1rem', backgroundColor: '#ebeceb', width: isMatch ? '335px' : '205.5px', height: isMatch ? '130px' : '85px' }}>
              <Stack style={{ paddingTop: '1rem', backgroundSize: '100% 100%', backgroundImage: `url(${rectang})`, backgroundPosition: 'absolute', width: isMatch ? '335px' : '205px', height: isMatch ? '158px' : '87px' }}>

                <Typography sx={{ marginLeft: '12px', fontFamily: 'Rubik', fontWeight: '500', fontSize: isMatch ? '18px' : '9px', color: '#fff' }}>
                  We ensure timely payment settlements <br />upon POD confirmation within the app,<br />
                  fostering a reliable partnership.</Typography>
              </Stack>
            </div>
            <div style={{ marginBottom: '100px', marginLeft: '1rem', backgroundColor: '#ebeceb', width: isMatch ? '335px' : '205px', height: isMatch ? '200px' : '100px' }}>
              <img src={serviceEra2}
                style={{ width: isMatch ? '230px' : '125px', height: isMatch ? '180px' : '89px', marginLeft: '30px' }}
                alt="Contact Image"
              />
            </div>
          </Grid>
        </Stack>

        <Grid item xs={12} sm={6} md={4} marginTop={isMatch ? '0' : '-6rem'} marginLeft={'5rem'}>
          <div style={{ marginTop: '1rem', marginLeft: '1rem', backgroundColor: '#ebeceb', borderRadius: '2PX', padding: '15px', width: isMatch ? '306px' : '175px', height: isMatch ? '40px' : '20px' }}>
            <Typography sx={{ fontFamily: 'Rubik', fontWeight: '500', fontSize: isMatch ? '26px' : '14px', color: '#5822DE' }}> Beyond Full Loads</Typography>
          </div>
          <div style={{ marginBottom: '1px', marginLeft: '1rem', backgroundColor: '#ebeceb', width: isMatch ? '335px' : '205.5px', height: isMatch ? '130px' : '85px' }}>
            <Stack style={{ backgroundSize: '100% 100%', backgroundImage: `url(${rectang})`, backgroundPosition: 'absolute', width: isMatch ? '335px' : '205px', height: isMatch ? '158px' : '105px' }}>

              <Typography sx={{ paddingTop: '1rem', marginLeft: '12px', fontFamily: 'Rubik', fontWeight: '500', fontSize: isMatch ? '18px' : '9px', color: '#fff' }}>
                Unlike competitors solely focused on<br /> bulk shipments, Dlx Logistics Private<br />
                Limited caters to B2C needs,<br /> supporting your daily business operations.</Typography></Stack>
          </div>
          <div style={{ marginBottom: '100px', marginLeft: '1rem', backgroundColor: '#ebeceb', width: isMatch ? '335px' : '205px', height: isMatch ? '200px' : '100px' }}>
            <img src={serviceEra3}
              style={{ width: isMatch ? '230px' : '125px', height: isMatch ? '180px' : '89px', marginBottom: '10px', marginLeft: '40px' }}
              alt="Contact Image"
            />
          </div>
        </Grid>
      </Stack>

      <Stack style={{ backgroundSize: '100% 100%', backgroundImage: `url(${vector})`, backgroundPosition: 'absolute', marginTop: isMatch ? '0' : '-60px' }}>
        <Typography sx={{ fontSize: isMatch ? "25px" : "14px", color: "#fff", textAlign: 'center', paddingTop: isMatch ? '60px' : '20px', fontFamily:'Poppins', fontWeight: isMatch ? 500 : 500, }}>
          Shipping Made Easy <br /> <span style={{ color: '#E11E31', fontSize: isMatch ? '30px' : '14px' }}>[</span><span style={{ fontSize: isMatch ? '30px' : '14px' }}> Streamline Your Business with DLX Today </span><span style={{ color: '#E11E31', fontSize: isMatch ? '30px' : '14px' }}>]</span> <br />We Handle the Rest
        </Typography>
        <Button onClick={() => navigate("/contact")} variant="contained" sx={{
          background: "#E11E31", color: "#fff", width: isMatch ? '173px' : '119px',
          height: isMatch ? '46px' : '25px', borderRadius: '25px', textTransform: "none", display: "flex", alignItems: "center",
          gap: 4, marginTop: { xs: '25px', md: '30px' }, marginLeft: { xs: '140px', md: '600px' }, fontSize: { xs: '10px', md: '18px' },
          fontFamily: 'Poppins', fontWeight: '500', textAlign: 'center', marginBottom: '40px'
        }}>
          JOIN WITH US
        </Button>
      </Stack>
      <Footer />
    </>
  );
}

export default ServicesPage;




