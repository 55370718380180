import React from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Navbar from './components/Navbar/navbar';
import HomePage from './pages/homePg';
import AboutUsPage from './pages/aboutUsPg';
import ServicesPage from './pages/servicesPg';
import FaqsPage from './pages/faqsPg';
import ContactUsPage from './pages/contactUsPg';
import CostEstimation from './pages/costestimation';
import { createTheme, ThemeProvider } from "@mui/material"
import './App.css';
import PrivacyPolicy from './pages/privacyPolicy';

const theme = createTheme({
  palette: {
    primary: {
      main: "#5E47DD",
    },
    secondary: {
      main: "#949494"
    },
    action: {
      hover: "#E8E4FB"
    },
    otherColors: {
      white: "#ffff",
      txtFieldColor: "#F5F6F8"
    }
  },
  typography: {
    fontFamily: [
      "Rajdhani", "Arial", "sans-serif",
    ].join(','),
    button: {
      textTransform: 'none'
    }
  },
})
function App() {
  return (
    <ThemeProvider theme={theme}>
    <Router>
      <Navbar />
      <Routes>
       <Route path="/" element={<HomePage />} />
       <Route path="/about" element={<AboutUsPage />} />
        <Route path="/contact" element={<ContactUsPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/costestimation" element={<CostEstimation />} />
        <Route path="/faq" element={<FaqsPage />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        
      </Routes>
    </Router>
    </ThemeProvider>
  );
}

export default App;
