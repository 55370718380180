import { Typography,Stack} from '@mui/material';

import React from "react";
import Slider from "react-slick";

function Feedback() {
  const settings = {

    // dots: true,
    // infinite: true,
    // slidesToShow: 3,
    // slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2000
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,
    swipeToSlide: true,
dots:true,
    centerMode: true,
  
  };

  const settings1 = {


    className: "center",
    infinite: true,
    centerPadding: "2px",
    slidesToShow: 3,
    speed: 500,
    swipeToSlide: true,
dots:true,
    centerMode: true,
  };
  return (
    <div className="slider-container">
     { /*Website View */}
     <Stack display={{ xs: "none", lg: "flex" }}>
      <Slider {...settings}>
       
          <div>
            <div style={{color: '#fff', backgroundColor: '#360E91', padding: '15px', borderRadius: '5%', marginLeft: '6rem', marginTop: '20px', width: '200px', height: '14rem' }}>
                   <Typography style={{ color: '#fff', fontSize: '15px', fontWeight: '400', marginTop: '20px', fontFamily: 'Rubik', marginLeft: '1rem',  }}>
                      "Done several shipment requests recently with them and it was fulfilled without any issues.."</Typography>
                    <Typography sx={{ fontFamily: 'Rajdhani', fontWeight: '600', fontSize: '20px', marginTop: '50px', marginLeft: '4.8rem' }}>Servin</Typography>
                    <Typography sx={{ fontFamily: 'Rajdhani', fontWeight: '600', fontSize: '15px', marginLeft: '4rem' }}>July,18,2020</Typography>
                  </div>
                  </div>
        <div>
          <div style={{color: '#fff' , backgroundColor: '#360E91', padding: '15px', borderRadius: '5%', marginLeft: '5rem', marginTop: '20px', width: '200px', height: '14rem' }}>
                    <Typography style={{ color: '#fff', fontSize: '15px', fontWeight: '400', marginTop: '20px', fontFamily: 'Rubik', marginLeft: '1rem' }}>
                      “Their packing skills are legendary with a huge range of packing materials to ensure.”
                    </Typography>
                   <Typography sx={{ fontFamily: 'Rajdhani', fontWeight: '600', fontSize: '20px', marginTop: '50px', marginLeft: '4rem' }}>Johnson</Typography>
                    <Typography sx={{ fontFamily: 'Rajdhani', fontWeight: '600', fontSize: '15px', marginLeft: '3rem' }}>January, 09, 2021</Typography>
                 </div>
        </div>
        <div>
           <div style={{color: '#fff' , backgroundColor: '#360E91', padding: '15px', borderRadius: '5%', marginLeft: '5rem', marginTop: '20px', width: '200px', height: '14rem' }}>
                     <Typography style={{ color: '#fff', fontSize: '15px', fontWeight: '400', fontFamily: 'Rubik', marginTop: '20px', marginLeft: '1rem', }}>
                      “Your team consistently meets their goals often exceeding expectations.”
                    </Typography>
                   <Typography sx={{ fontFamily: 'Rajdhani', fontWeight: '600', fontSize: '20px', marginTop: '70px', marginLeft: '3.8rem' }}>Deepshika</Typography>
                    <Typography sx={{ fontFamily: 'Rajdhani', fontWeight: '600', fontSize: '15px', marginLeft: '3.6rem' }}>March, 20,2021</Typography>
                  </div>
        </div>
        
      </Slider>
      </Stack>

      { /*Mobile View */}
      <Stack display={{ xs: "flex", lg: "none" }} >
      <Slider {...settings1}>
        <div>
          <h3>
          <div style={{ color: '#fff', backgroundColor: '#360E91', padding: '5px', borderRadius: '10%', marginLeft: '0.5rem', marginTop: '20px', width: '110px', height: '150px' }}>
                            <Typography style={{ color: '#fff', fontSize: '10px', fontWeight: '400', marginTop: '10px', fontFamily: 'Rubik', marginLeft: '0.7rem', marginRight: '0.5rem' }}>
                                "Done several shipment requests recently with them and it was fulfilled without any issues.."</Typography>
                            <Typography sx={{ fontFamily: 'Rajdhani', fontWeight: '600', fontSize: '14px', marginTop: '3px', marginLeft: '2rem' }}>Servin</Typography>
                            <Typography sx={{ fontFamily: 'Rajdhani', fontWeight: '600', fontSize: '10px', marginLeft: '1.5rem' }}>July,18,2020</Typography>
                        </div></h3>
        </div>
        <div>
          <h3> <div style={{ color: '#fff', backgroundColor: '#360E91', padding: '5px', borderRadius: '10%', marginLeft: '0.3rem', marginTop: '5px', width: '110px', height: '150px' }}>
                            <Typography style={{ color: '#fff', fontSize: '10px', fontWeight: '400', marginTop: '10px', fontFamily: 'Rubik', marginLeft: '0.5rem' }}>
                                “Their packing skills are legendary with a huge range of packing materials to ensure.”
                            </Typography>
                            <Typography sx={{ fontFamily: 'Rajdhani', fontWeight: '600', fontSize: '14px', marginTop: '8.5px', marginLeft: '2rem' }}>Johnson</Typography>
                            <Typography sx={{ fontFamily: 'Rajdhani', fontWeight: '600', fontSize: '10px', marginLeft: '1.2rem' }}>January, 09, 2021</Typography>
                        </div></h3>
        </div>
        <div>
          <h3> <div style={{ color: '#fff', backgroundColor: '#360E91', padding: '5px', borderRadius: '10%', marginLeft: '0.2rem', marginTop: '20px',marginRight:'0rem', width: '110px', height: '150px' }}>
                            <Typography style={{ color: '#fff', fontSize: '10px', fontWeight: '400', fontFamily: 'Rubik', marginTop: '10px', marginLeft: '1rem', marginRight: '0rem' }}>
                                “Your team consistently meets their goals often exceeding expectations.”
                            </Typography>
                            <Typography sx={{ fontFamily: 'Rajdhani', fontWeight: '600', fontSize: '14px', marginTop: '15px', marginLeft: '1.5rem' }}>Deepshika</Typography>
                            <Typography sx={{ fontFamily: 'Rajdhani', fontWeight: '600', fontSize: '10px', marginLeft: '1.5rem' }}>March, 20,2021</Typography>
                        </div></h3>
        </div>
        
      </Slider>
      </Stack>
    </div>
  );
}

export default Feedback;