import React from 'react';
import { Typography,  Stack,useMediaQuery } from '@mui/material';
import servicesBg from '../assets/images/servicesBg.png'
import faqs from '../assets/images/faqsheader.png';
import Faq1 from '../components/faq1';
import Footer from '../components/Footer';

function FaqsPage() {
  const isMatch=useMediaQuery("(min-width:650px)")
  return (
    <>
      <Stack style={{ backgroundImage: `url(${faqs})`, backgroundSize: '100% 100%', backgroundPosition: 'center',width:isMatch?'1420px': '393px' , height:  isMatch? '380px': '170px'  }}>
        <Typography sx={{ fontSize:isMatch? "50px":"22px", color: "#fff", justifyContent: "left", pt: isMatch ? 38 : 15, paddingLeft: isMatch?"100px":"20px", fontFamily: 'Rajdhani', fontWeight: isMatch?600:700 }}>FAQ's</Typography>
      </Stack>

      <Stack style={{ backgroundImage: `url(${servicesBg})`, backgroundSize: 'cover', backgroundPosition: 'absolute' }}>
        <Stack direction={'row'} marginTop={isMatch?'40px':'40px'} marginBottom={isMatch?'30px':'20px'} marginRight={'50PX'}>
          <Stack marginLeft={isMatch?'140px':'30px'}>
            <Typography sx={{ fontSize:isMatch? '40px':'16px', fontFamily: isMatch?'Poppins':'Poppins', fontWeight:isMatch? '500':'500' }}>Find answers for common questions</Typography>
            <Typography sx={{ fontSize: isMatch?'40px':'16px', fontFamily: isMatch?'Poppins':'Poppins', fontWeight:isMatch? '500':'500', color: '#5923DB' }}>about DLX's Logistic.</Typography>
            <Typography  sx={{ fontWeight:isMatch? '500':'normal',marginBottom:isMatch?'30px':'30px', ml: '10px',mt:'10px', fontFamily: isMatch?'Poppins':'Inter',fontSize:isMatch?'16px':'16px' }}>If you can't find an answer,contact us and we will be happy to help.</Typography>
         
          </Stack>
        </Stack>

        <Stack marginLeft={isMatch?'160px':'30px'} marginTop={isMatch?'0px':'-20px'} marginBottom={isMatch?'50px':'20px'} >
          <Faq1/>
        </Stack>
      </Stack>

      <Footer/>
    </>
  );
}

export default FaqsPage;