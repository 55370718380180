import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {  useMediaQuery } from '@mui/material';

const ResponsiveCard = ({ title,  address, phone, email }) => (
 
  <Card sx={{ backgroundColor: '#f7f7f7', color: '#202020', p: 1, width: '100%%', height: '70%', borderRadius: '5px' }}>
        <Typography sx={{fontSize: { xs: '12px', md: '20px' },fontWeight: '600',marginLeft:{ xs: '-20px', md: '-60px' },width:'120%', display: "flex",backgroundColor:'#301375',color:'#fff',height:'50px',alignItems:'center',justifyContent:"center"}}>
        <LocationOnIcon/>
        &emsp;{title}
      </Typography>
    <CardContent>
      <Typography sx={{ fontSize: { xs: '12px', md: '18px' }, fontFamily: 'Inter', fontWeight: '700', marginTop: '10px' }}> {address}</Typography>
      <Typography sx={{ fontSize: { xs: '12px', md: '35px' }, fontFamily: 'Inter', fontWeight: '700', marginTop: '10px' }}></Typography>
      <Typography sx={{ fontSize: { xs: '12px', md: '18px' }, fontFamily: 'Inter', fontWeight: '700', marginTop: '10px' }}>Phone &emsp;:  &emsp;  {phone}</Typography>
      <Typography sx={{ fontSize: { xs: '12px', md: '15px' }, fontFamily: 'Inter', fontWeight: '100', marginTop: '10px' }}></Typography>
      <Typography sx={{ fontSize: { xs: '12px', md: '18px' }, fontFamily: 'Inter', fontWeight: '700', marginTop: '10px' }}>Email &emsp;:   &emsp;  {email}</Typography>
      <Typography sx={{ fontSize: { xs: '12px', md: '15px' }, fontFamily: 'Inter', fontWeight: '100', marginTop: '10px' }}></Typography>
    </CardContent>
  </Card>
);


function Contact() {
  const isMatch = useMediaQuery("(min-width:650px)")
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="center"
          alignItems="center"
          marginTop={{ xs: '10px', md: '30px' }}
          marginLeft={{ xs: '10px', md: '110px' }}
          marginRight={{ xs: 'auto', md: 'auto' }}
          height={isMatch?'380px':'270px'}
          width={isMatch?'380px':'270px'}
        >
          <ResponsiveCard
            title="NORTH ZONE"
            address="OPP: Big Bazar, Near, Shanidev Mandir,
            Kankaria Road, Raipur Gate,
            Ahmedabad - 22; "
            phone="7517777100"
            email="DLX@gmail.com"
          />
        </Stack>
      </Grid>

      <Grid item xs={12} md={4}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="center"
          alignItems="center"
          marginTop={{ xs: '-50px', md: '30px' }}
          marginLeft={{ xs: '10px', md: '60px' }}
          marginRight={{ xs: 'auto', md: '50px' }}
          height={isMatch?'380px':'270px'}
          width={isMatch?'380px':'270px'}
        >
          <ResponsiveCard
            title="CENTER ZONE"
            address="No -5, Harman Complex,Opp- Gupta Weigh Bridge,
            Purna Village,
            Bhiwandi TK
            ,Thane Dist"
            phone="7517777200"
            email="DLX@gmail.com"
          />
        </Stack>
      </Grid>

      <Grid item xs={12} md={4}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="center"
          alignItems="center"
          marginTop={{ xs: '-50px', md: '30px' }}
          marginLeft={{ xs: '10px', md: '0px' }}
          marginRight={{ xs: 'auto', md: '100px' }}
          height={isMatch?'380px':'270px'}
          width={isMatch?'380px':'270px'}
        >
          <ResponsiveCard
            title="SOUTH ZONE"
            address="6/2818-B, Elayarasanenthal Main Road,
            Kovilpatti - 628501 &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;"
            phone="7517777200"
            email="DLX@gmail.com"
          />
        </Stack>
      </Grid>
    </Grid>
  );
}
export default Contact;

